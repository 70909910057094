import ArticleCard from "@/common/cards/article/article"
import EventCard from "@/common/cards/event/event"
import MemberCard from "@/common/member/article-card-with-image/article-card-with-image"
import Slider from "@/common/slider/slider"
import Block from "@/components/wrappers/block"
import styles from "./post-slider.module.css"
import cx from "classnames"

export default function PostSlider({ block }) {
  const {
    cards,
    heading,
    text,
    theme,
    image,
    button,
    blockGap,
    cardType,
    accentColor
  } = block.attrs

  if (cards.length === 0) {
    return null
  }

  const wrapperClasses = [
    styles.postSlider,
    styles[theme],
    theme === "image" ? styles.overlay : ""
  ]
  let backgroundImage = {}

  if (image) {
    backgroundImage = { backgroundImage: "url(" + image.url + ")" }
  }

  return (
    <Block
      block={block}
      width="bleed"
      gap={blockGap ?? "normal"}
      className={cx(wrapperClasses)}
      style={backgroundImage}>
      <Slider
        id={block.attrs.context}
        heading={heading}
        text={text}
        theme={theme}
        accentColor={accentColor}
        button={button}>
        {cards.map((post, index) => {
          let Component = null

          switch (cardType) {
            case "calendar":
              Component = EventCard
              break
            case "member-article":
              return <MemberCard key={index} article={post} />
            default:
              Component = ArticleCard
              break
          }

          if (!Component) {
            return null
          }

          return <Component key={index} post={post} />
        })}
      </Slider>
    </Block>
  )
}
