import { useState, useRef, useEffect } from "react"
import SwiperCore, { Navigation, Scrollbar } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/css/bundle"
import Arrow from "@/common/icons/arrow"
import Button from "@/common/button/button"
import styles from "./slider.module.css"
import Block from "@/components/wrappers/block"
import classNames from "classnames"

export default function Slider({
  id,
  heading,
  text,
  children,
  button,
  theme,
  accentColor
}) {
  const containerRef = useRef(null)
  const [offset, setOffset] = useState(null)
  const [mySwiper, setMySwiper] = useState(null)

  useEffect(() => {
    if (!children || children.length === 0) {
      return null
    }
    let timeoutId = null

    const resizeListener = () => {
      clearTimeout(timeoutId)

      timeoutId = setTimeout(() => {
        setOffset(containerRef.current.offsetLeft)
      }, 150)
    }

    window.addEventListener("resize", resizeListener)
    setOffset(containerRef.current.offsetLeft)

    if (mySwiper) {
      if (mySwiper.params) {
        mySwiper.params.slidesOffsetBefore = offset
        mySwiper.params.slidesOffsetAfter = offset
        mySwiper.update()
      }
    }

    return () => {
      // remove resize listener
      window.removeEventListener("resize", resizeListener)
    }
  }, [mySwiper, offset, children])

  if (!children || children.length === 0) {
    return null
  }

  SwiperCore.use([Navigation, Scrollbar])

  const settings = {
    onSwiper: (swiper) => {
      setMySwiper(swiper)
    },
    scrollbar: {
      el: ".swiper-scrollbar-" + id,
      draggable: true,
      dragSize: 200
    },
    navigation: {
      nextEl: ".swiper-nav-next-" + id,
      prevEl: ".swiper-nav-prev-" + id
    },
    slidesPerView: "auto",
    spaceBetween: 40,
    breakpoints: {
      375: {
        slidesPerView: 1,
        spaceBetween: 20
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 40
      },
      1280: {
        slidesPerView: 4,
        spaceBetween: 40
      },
      1600: {
        slidesPerView: 5,
        spaceBetween: 40
      },
      2048: {
        slidesPerView: 6,
        spaceBetween: 40
      }
    }
  }

  return (
    <>
      <Block
        block={{ name: "slider-heading" }}
        width="full"
        gap="none"
        className={classNames(styles["bg" + theme], styles.heading)}>
        <h2 ref={containerRef}>{heading}</h2>
        {text && <p dangerouslySetInnerHTML={{ __html: text }}></p>}
      </Block>
      <Swiper
        {...settings}
        modules={[Scrollbar]}
        id={id}
        className={styles.slider}>
        {children.map((child, index) => {
          return (
            <SwiperSlide className={styles.slide} key={`s-${index}`}>
              {child}
            </SwiperSlide>
          )
        })}
      </Swiper>
      <Block
        block={{ name: "slider-controllers" }}
        className={classNames(styles.controllers, styles.slider)}
        width="full"
        gap="none">
        <div className="flex">
          {button.url && (
            <Button
              tagName="a"
              target={button.target}
              href={button.url}
              className={styles.cta}
              theme={button.theme}>
              {button.label}
            </Button>
          )}
        </div>
        <div className={styles.scrollbarWrapper}>
          <div className={styles.scrollbar}>
            <div
              className={classNames(
                styles.swiperScrollbar,
                "swiper-scrollbar-" + id,
                accentColor
              )}></div>
          </div>
        </div>
        <div className={styles.navigation}>
          <button
            className={classNames(
              styles.button,
              "prev",
              "swiper-nav-prev-" + id
            )}>
            <Arrow />
          </button>
          <button
            className={classNames(styles.button, "swiper-nav-next-" + id)}>
            <Arrow />
          </button>
        </div>
      </Block>
    </>
  )
}
